<template>
  <Disclosure as="nav" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16 mr-auto">
        <div class="flex ml-auto">
          <div class="-ml-2 mr-2 flex items-center md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <button class="block h-6 w-6 ml-auto" aria-hidden="true">
                <span v-if="!open">X</span>
                <span v-else>X</span>
              </button>
            </DisclosureButton>
          </div>
          <div class="hidden md:ml-6 md:flex md:space-x-8">
            <template v-if="links">

              <template v-for="(link, index) in links" :key="index">
                <template v-if="user && user.permissions.includes(link.permission)">
                  <router-link :to="link.path" class="text-gray-900 dark:text-gray-300 inline-flex items-center px-1 pt-1 text-sm font-medium" :class="[getActiveLink(link.path) ? 'border-main4 border-b-2' : '']">
                    {{ link.title }}
                  </router-link>
                </template>
              </template>

            </template>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="pt-2 pb-3 space-y-1 bg-white">

        <template v-if="links">
          <template v-for="link in links" :key="link.path">
            <template v-if="user && user.permissions.includes(link.permission)">
              <router-link :to="link.path" class="block pl-3 pr-4 py-2 text-base font-medium sm:pl-5 sm:pr-6" :class="[getActiveLink(link.path) ? 'border-main4 border-l-4' : '']">
                {{ link.title }}
              </router-link>
            </template>
          </template>
        </template>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { mapGetters } from "vuex"

export default {
  name: "TopBarNavigation",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'currentPage',
      user: "user"
    }),
  },
  methods: {
    getActiveLink(route) {
      return (route == this.currentPage) ? true : false;
    },
  }
}
</script>